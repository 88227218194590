import * as React from "react";

export interface ISplosniPogojiProps {}

export function SplosniPogoji(props: ISplosniPogojiProps) {
  return (
    <div>
      <h1>Stran je v izdelavi</h1>
      <p>
        Spletna stran je še v procesu izdelave in ni namenjena splošni rabi.
      </p>
    </div>
  );
}

export default SplosniPogoji;
